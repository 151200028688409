import React, { useState, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";
import "../Style/home.css";
import { makeStyles } from "@material-ui/core";
import { createStore, action, useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(8),
    marginLeft: "20%",
    width: "20%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Home() {
  const classes = useStyles();
  const [userid, setuserid] = useState("");
  const [ifnew, setifnew] = useState(false);

  const login = useStoreActions((actions) => actions.login);
  const loggedIn = useStoreState((state) => state.loggedIn);
  const setnewuser = useStoreActions((actions) => actions.setnewuser);
  const setid = useStoreActions((actions) => actions.setid);


  useEffect(() => {
    if (userid !== "" && ifnew === true) {
      setnewuser(true);
    }
  }, [ifnew]);

  return (
    <div className="container">
      <form className="taskid" noValidate autoComplete="off">
        <TextField
          id="standard-basic"
          label="Please enter your user name"
          aria-describedby="my-helper-text"
          className="idinput"
          required
          onChange={(e) => {
            setuserid(e.target.value);
          }}
        />
        <br />
        <Button
          className={classes.margin}
          variant="contained"
          color="primary"
          onClick={(e) => {
            console.log("This is user id" + userid)
            if(userid !== ""){
              login(true)
            }
            setid(userid);
          }}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default Home;
